import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShip } from '@fortawesome/free-solid-svg-icons'
import { faDiamond } from '@fortawesome/free-solid-svg-icons'
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StaticImage
          src="../images/homepage_boat.jpg"
          alt="Boat underway"
          className="rounded-md w-120 h-60 mb-10 "
        />

      <div className="bg-blue-100 rounded-md p-6 flex flex-row justify-between flex-wrap">
        <div className="h-full">
          <h2 className="text-3xl">Services Offered:</h2>
          <ul className="mt-2 ml-4">
            <li>Fishing charters</li>
            <li>Lodging</li>
            <li>Marina</li>
            <li>Boat rides</li>
            <li>Marine gas</li>
            <li>Bait</li>
            <li>Fish cleaning</li>
            <li>Boat repairs</li>
          </ul>

          <a href="tel:315-938-5234">
            <div className="flex flex-col mt-4">
              <h2 className="text-3xl">For Reservations Call:</h2>
              <div className="text-xl">
              315-938-5234&nbsp;<sup><FontAwesomeIcon icon={faExternalLinkAlt} className="text-gray-900" /></sup>
              </div>
            </div>
          </a>
          <h3 className="mt-4">Located 19 miles south of Watertown, NY <br /> and 60 miles north of
          Syracuse, NY</h3>
        </div>
        <div className="mx-auto">
          <StaticImage
            src="../images/ron.jpeg"
            alt="Ron Driving a boat"
            className="rounded-md w-60 lg:w-80 mt-6 lg:mt-0"
          />
        </div>

      </div>


<div className="flex flex-row mt-10 flex-wrap md:flex-nowrap">
  <div className="flex flex-col md:w-1/4">
        <StaticImage
          src="../images/index_lighthouse.jpg"
          alt="Lighthouse"
          className="rounded-md"
        />
        <span>STONY POINT LIGHTHOUSE</span>
        </div>
        <div className="flex flex-col md:w-3/4 md:ml-4 mt-6 md:mt-0">
        <StaticImage
          src="../images/fish_index.jpg"
          alt="fish"
          className="rounded-md "
        />
        <span>SMALLEST FISH WAS 17"!</span>
        </div>
</div>

  </Layout>
)

export default IndexPage
